/* eslint-disable import/no-unresolved */
import React from 'react';
import { Image, ImageFit } from 'office-ui-fabric-react';
import { PUBLIC_PATHS } from 'config';
import { formatDatetimeString } from 'helpers';

const returnPageCount = (prevState, payload) => {
  if (payload === 'increment') {
    return prevState + 1;
  }

  if (payload === 'decrement') {
    return prevState - 1;
  }

  return payload;
};

export const initialState = {
  columns: [
    {
      key: 'id',
      name: '#',
      fieldName: 'id',
      minWidth: 30,
      maxWidth: 40,
      isResizable: true,
      data: 'number',
    },
    {
      key: 'image',
      name: 'Media',
      fieldName: 'image',
      onRender: item => (
        <div style={{ width: '300px', height: '169px' }}>
          {item.image.length > 0 && (
            <Image
              src={`${PUBLIC_PATHS.assets}/300x169/assets/${item.image}`}
              imageFit={ImageFit.cover}
              maximizeFrame
            />
          )}
        </div>
      ),
      minWidth: 300,
      maxWidth: 300,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'title',
      name: 'Title',
      fieldName: 'title',
      minWidth: 120,
      maxWidth: 200,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'createdAt',
      name: 'Created At',
      fieldName: 'createdAt',
      onRender: d => formatDatetimeString(d.createdAt),
      minWidth: 120,
      maxWidth: 200,
      isResizable: true,
      isSorted: true,
      isSortedDescending: true,
      data: 'timestamp',
    },
    {
      key: 'updatedAt',
      name: 'Updated At',
      fieldName: 'updatedAt',
      onRender: d => formatDatetimeString(d.updatedAt),
      minWidth: 120,
      maxWidth: 200,
      isResizable: true,
      data: 'timestamp',
    },
    {
      key: 'actions',
      name: '',
    },
  ],
  errors: [],
  items: [],
  filters: {
    text: '',
  },
  // cursor pagination
  cursorPaginationObject: {
    first: 10,
  },
  numberOfRecordsPerPage: 10,
  pageCount: 1,
  pageInfo: {},
  totalCount: 0,
  // rest
  displayQuickEdit: false,
  loading: true,
  quickEditMutated: false,
  selectedOwnersAreaResourceId: null,
  showFilter: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCHING':
      return {
        ...state,
        loading: true,
      };

    case 'UPDATE_ERRORS':
      return {
        ...state,
        errors: action.payload,
      };

    case 'TOGGLE_FILTER_DISPLAY':
      return {
        ...state,
        showFilter: !state.showFilter,
      };

    case 'UPDATE_FILTER_VALUE':
      return {
        ...state,
        pageCount: 1,
        filters: {
          ...state.filters,
          [action.field]: action.payload,
        },
      };

    case 'SELECT_QUICK_EDIT':
      return {
        ...state,
        selectedOwnersAreaResourceId: action.payload,
        displayQuickEdit: true,
      };

    case 'DISMISS_QUICK_EDIT':
      return {
        ...state,
        displayQuickEdit: false,
        selectedOwnersAreaResourceId: null,
      };

    case 'SET_QUICK_EDIT_MUTATED':
      return {
        ...state,
        quickEditMutated: !state.quickEditMutated,
      };

    case 'UPDATE_ITEMS':
      return {
        ...state,
        items: action.payload,
      };

    case 'UPDATE_COLUMNS':
      return {
        ...state,
        columns: action.payload.columns,
        items: action.payload.items,
      };

    case 'UPDATE_PAGINATED_ITEMS':
      return {
        ...state,
        totalCount: action.payload.totalCount,
        pageInfo: action.payload.pageInfo,
        items: action.payload.items,
        loading: false,
      };

    case 'UPDATE_PAGINATION_CRITERIA':
      return {
        ...state,
        cursorPaginationObject: action.payload.cursorPaginationObject,
        pageCount: (
          action.payload.pageCount
            ? returnPageCount(state.pageCount, action.payload.pageCount) : state.pageCount
        ),
        numberOfRecordsPerPage: (
          action.payload.numberOfRecordsPerPage || state.numberOfRecordsPerPage
        ),
      };

    default:
      break;
  }

  return state;
};
