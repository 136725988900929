/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useRef } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import md5 from 'md5';
import {
  Checkbox,
  DefaultButton,
  Dropdown,
  Label,
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
} from 'office-ui-fabric-react';
import { MediaPicker, MessageList } from 'components';
import { handleErrorResponse } from 'helpers';
import {
  ADD_ASSET_TO_RESOURCE,
  ADD_RESOURCES_TO_PARKS,
  CREATE_ASSET,
  GENERATE_PRESIGNED_URL,
  GET_RESOURCE_DATA,
  GET_RESOURCE,
  REMOVE_RESOURCES_FROM_PARKS,
  UPDATE_ASSET,
  UPDATE_OWNERS_AREA_RESOURCE,
} from './graphql';

/*
  NOTE:
    flow for creating/updating a resource:

    1) create/update asset
      1a) upload file if creating new asset
    2) flag the asset as being used on the owners area
    3) relate to a park (if required)
*/

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
};

const UpdateOwnersAreaResource = ({ client, ...otherProps }) => {
  const {
    match: {
      params: { id: ownersAreaResourceId },
    },
  } = otherProps;

  const [isCreatingNewOwnersAreaResource, setIsCreatingNewOwnersAreaResource] = (
    useState(Number.isNaN(parseInt(ownersAreaResourceId, 10))));

  const [state, setState] = useState({
    ownersAreaResource: {
      id: null,
      asset: {
        id: null,
        title: '',
        description: '',
        path: '',
        mediaId: null,
        size: null,
      },
      categoryId: null,
      parks: [],
    },
    initialSelectedParks: [],
    allAssetCategories: [],
    allParks: [],
  });
  const [loading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const [displayMediaPicker, setDisplayMediaPicker] = useState(false);

  // using refs for file select because input type="file" is uncontrolled
  const inputRef = useRef(null);

  useEffect(() => {
    (async () => {
      let ownersAreaResource = {};

      if (!isCreatingNewOwnersAreaResource) {
        const getOwnersAreaResource = await client.query({
          query: GET_RESOURCE,
          variables: {
            id: ownersAreaResourceId === 'create' ? state.ownersAreaResource.id : parseInt(ownersAreaResourceId, 10),
          },
        });

        // eslint-disable-next-line prefer-destructuring
        ownersAreaResource = getOwnersAreaResource.data.ownersAreaResource;
      }

      const { data: { allAssetCategories, allParks } } = await client.query({
        query: GET_RESOURCE_DATA,
      });

      const sortedCategories = allAssetCategories.sort((a, b) => a.name.localeCompare(b.name));
      const sortedParks = allParks.sort((a, b) => a.name.localeCompare(b.name));

      const ownersAreaResourceForState = !isCreatingNewOwnersAreaResource
        ? ownersAreaResource
        : state.ownersAreaResource;

      setState({
        ...state,
        allAssetCategories: sortedCategories,
        allParks: sortedParks,
        initialSelectedParks: ownersAreaResourceForState.parks,
        ownersAreaResource: ownersAreaResourceForState,
      });

      setIsLoading(false);
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatingNewOwnersAreaResource]);

  const handleInputChange = ({ target: { name, value, objectRef = '' } }) => {
    const ownersAreaResource = Object.assign({}, state.ownersAreaResource);
    // eslint-disable-next-line no-unused-expressions
    objectRef === '' ? ownersAreaResource[name] = value : ownersAreaResource[objectRef][name] = value;
    setState(prevState => ({ ...prevState, ownersAreaResource }));
  };

  const toggleSelectedPark = (e, parkId) => {
    const { target: { checked } } = e;
    const { ownersAreaResource: { parks } } = state;

    let newParksArray = [];

    if (checked) {
      newParksArray = [...parks, { id: parkId }];
    } else {
      newParksArray = parks.filter(({ id }) => parseInt(id, 10) !== parseInt(parkId, 10));
    }

    setState(prevState => ({
      ...prevState,
      ownersAreaResource: {
        ...prevState.ownersAreaResource,
        parks: newParksArray,
      },
    }));
  };

  const toggleSelectAllParks = () => {
    const selectedParksLength = state.ownersAreaResource.parks.length;
    const allParksLength = state.allParks.length;

    const parks = selectedParksLength === allParksLength
      ? [] : state.allParks.map(({ id }) => ({ id }));

    setState({
      ...state,
      ownersAreaResource: {
        ...state.ownersAreaResource,
        parks,
      },
    });
  };

  const validateData = () => {
    const errorsFound = [];

    const { current: { files } } = inputRef;

    if (!state.ownersAreaResource.categoryId) {
      errorsFound.push({
        title: 'Invalid Category',
        message: 'Please select a category',
      });
    }

    if (files.length === 0) {
      errorsFound.push({
        title: 'Invalid File',
        message: 'Please select a file to upload',
      });
    }

    const validFileTypes = [
      'image/jpeg',
      'image/png',
      'application/pdf',
    ];

    if (files.length > 0 && !validFileTypes.includes(files[0].type)) {
      errorsFound.push({
        title: 'Invalid File Type',
        message: 'Invalid file type. Must be one of; .jpeg, .png, .pdf',
      });
    }

    setErrors(errorsFound);

    return errorsFound.length === 0;
  };

  const getPresignedURL = async (filename, fileType) => {
    const response = await client
      .mutate({
        mutation: GENERATE_PRESIGNED_URL,
        variables: {
          input: {
            filename,
            path: 'owners-area-resources/',
            contentType: fileType,
          },
        },
      });

    if (!response) {
      throw new Error('No response. Failed at returning presigned URL.');
    }

    if (response.errors) {
      response.errors.forEach(({ message }) => {
        throw new Error(message);
      });
    }

    return response.data.generatePresignedUrl.url;
  };

  const handleFileSendRequest = async (presignedURL, file) => {
    // eslint-disable-next-line no-undef
    const response = await fetch(presignedURL, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
      mode: 'cors',
    });

    if (response.status !== 200) {
      throw new Error('Failed at sending file.');
    }
  };

  const handleAssetToOwnersAreaAssociation = async (assetId) => {
    const { ownersAreaResource: { categoryId } } = state;

    const response = await client.mutate({
      mutation: ADD_ASSET_TO_RESOURCE,
      variables: {
        input: [{ assetId, categoryId }],
      },
    });

    if (!response) {
      throw new Error('No response. Failed at adding associating asset to Owners Area resource');
    }

    if (response.errors) {
      response.errors.forEach(({ message }) => {
        throw new Error(message);
      });
    }

    return ({
      ownersAreaResourceId: response.data.addAssetsToOwnersAreaResources[0].id,
    });
  };

  const handleResourceToParkAssociations = async (type, arrayOfParkIds, resourceId) => {
    const meta = {
      add: {
        mutation: ADD_RESOURCES_TO_PARKS,
        response: 'addOwnersAreaResourcesToParks',
      },
      remove: {
        mutation: REMOVE_RESOURCES_FROM_PARKS,
        response: 'removeOwnersAreaResourcesFromParks',
      },
    };

    const input = arrayOfParkIds.map(parkId => ({
      ownersAreaResourceId: resourceId,
      parkId,
    }));

    if (input.length > 0) {
      const response = await client.mutate({
        mutation: meta[type].mutation,
        variables: {
          input,
        },
      });

      if (!response) {
        throw new Error(`No response for mutation ${meta[type].response}.`);
      }

      if (response.errors) {
        response.errors.forEach(({ message }) => {
          throw new Error(message);
        });
      }

      if (response.data) {
        setState({
          ...state,
          initialSelectedParks: state.ownersAreaResource.parks,
        });
      }
    }
  };

  /*
    This function returns an object containing server data which
    makes it easier to access necessary data and pass it through to be used in
    other mutations, rather than trying to update and pull the data from
    the state for each mutation.
  */
  const handleAssetMutation = async () => {
    const {
      ownersAreaResource: {
        asset: {
          __typename,
          path,
          id,
          size,
          ...input
        },
      },
    } = state;

    let fileExtension = '';

    if (isCreatingNewOwnersAreaResource) {
      // gets filename extension when names are in same convention as validFileTypes
      [fileExtension] = inputRef.current.files[0].type.match(/(?<=\/)([A-Za-z]*)/g);
    }

    const newFilename = md5(
      `${new Date().getTime().toString()}-${(Math.random() * 10000)
        .toString()
        .replace('.', '')}`,
    ).concat(`.${fileExtension}`);

    const response = await client.mutate({
      mutation: isCreatingNewOwnersAreaResource ? CREATE_ASSET : UPDATE_ASSET,
      variables: {
        ...(!isCreatingNewOwnersAreaResource && { id }),
        input: {
          ...input,
          ...(isCreatingNewOwnersAreaResource && { path: newFilename }),
          size: isCreatingNewOwnersAreaResource ? inputRef.current.files[0].size : size,
        },
      },
    });

    if (!response) {
      throw new Error(`No response. Failed at ${isCreatingNewOwnersAreaResource ? 'creating' : 'updating'} asset.`);
    }

    if (response.errors) {
      response.errors.forEach(({ message }) => {
        throw new Error(message);
      });
    }

    if (response && response.data) {
      const responseMapped = isCreatingNewOwnersAreaResource
        ? response.data.createAsset
        : response.data.updateAsset;

      setState({
        ...state,
        ownersAreaResource: {
          ...state.ownersAreaResource,
          asset: {
            id: responseMapped.id,
            path: responseMapped.path,
            ...state.ownersAreaResource.asset,
          },
        },
      });

      if (isCreatingNewOwnersAreaResource) {
        // eslint-disable-next-line consistent-return
        return ({
          filename: newFilename,
          assetId: responseMapped.id,
        });
      }
    }

    return false;
  };

  const handleOwnersAreaResourceMutation = async () => {
    const {
      ownersAreaResource: {
        id,
        categoryId,
      },
    } = state;

    const response = await client.mutate({
      mutation: UPDATE_OWNERS_AREA_RESOURCE,
      variables: {
        id: parseInt(id, 10),
        input: {
          assetCategoryId: parseInt(categoryId, 10),
        },
      },
    });

    if (!response) {
      throw new Error('No response. Failed at updating Owners Area resource');
    }

    if (response.errors) {
      response.errors.forEach(({ message }) => {
        throw new Error(message);
      });
    }
  };

  const handleSave = async () => {
    // eslint-disable-next-line no-undef
    window.scrollTo({ top: 0, behavior: 'smooth' });

    setErrors([]);
    setSuccessMessages([]);

    const { ownersAreaResource: { parks }, initialSelectedParks } = state;
    const initialParkIds = initialSelectedParks.map(p => p.id);
    const parkIds = parks.map(p => p.id);

    const additions = parkIds.filter(id => !initialParkIds.includes(id));
    const removals = initialParkIds.filter(id => !parkIds.includes(id));

    if (isCreatingNewOwnersAreaResource) {
      if (validateData()) {
        const { current: { files } } = inputRef;
        const file = files[0];

        try {
          const { filename, assetId } = await handleAssetMutation();

          // handles saving the file
          const presignedURL = await getPresignedURL(filename, file.type);
          await handleFileSendRequest(presignedURL, file);

          const { ownersAreaResourceId: resourceId } = (
            await handleAssetToOwnersAreaAssociation(assetId));

          if (additions.length > 0) {
            await handleResourceToParkAssociations('add', additions, resourceId);
          }

          setState({
            ...state,
            ownersAreaResource: { ...state.ownersAreaResource, id: resourceId },
          });
          setIsCreatingNewOwnersAreaResource(false);
          setSuccessMessages([{ message: 'Resource created' }]);
        } catch (e) {
          setErrors([{ message: e }]);
        }
      }
    } else {
      try {
        await handleOwnersAreaResourceMutation();
        await handleAssetMutation();

        if (additions.length > 0) {
          await handleResourceToParkAssociations('add', additions, state.ownersAreaResource.id);
        }

        if (removals.length > 0) {
          await handleResourceToParkAssociations('remove', removals, state.ownersAreaResource.id);
        }

        setSuccessMessages([{ message: 'Resource updated' }]);
      } catch (e) {
        setErrors([{ message: e }]);
      }
    }
  };

  if (loading) {
    return <Spinner label="Loading please wait..." />;
  }

  return (
    <Pivot styles={{ icon: { paddingRight: '6px' } }}>
      <PivotItem headerText="Details" itemIcon="Edit">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          <Stack tokens={{ childrenGap: 18 }}>
            {errors.length > 0 && <MessageList messages={errors} messageFunction={setErrors} messageType="error" />}
            {successMessages.length > 0 && <MessageList messages={successMessages} messageFunction={setSuccessMessages} messageType="success" />}

            <TextField
              label="File ID"
              name="id"
              value={state.ownersAreaResource.asset.id}
              prefix="#"
              type="number"
              readOnly
            />

            <Dropdown
              label="Category"
              placeholder="Select"
              selectedKey={
                state.ownersAreaResource.categoryId
                  ? state.ownersAreaResource.categoryId
                  : undefined
              }
              options={state.allAssetCategories.map(({ id: key, name: text }) => ({
                key,
                text,
              }))}
              name="categoryId"
              onChange={(e, item) => handleInputChange({
                target: { name: 'categoryId', value: item.key },
              })}
              required
            />

            <TextField
              label={`Title: (${
                state.ownersAreaResource.asset.title.length
              }/50 characters)`}
              name="title"
              placeholder="Enter a title.."
              value={state.ownersAreaResource.asset.title}
              onChange={e => handleInputChange({
                target: {
                  name: e.target.name,
                  value: e.target.value,
                  objectRef: 'asset',
                },
              })}
              ariaLabel="Title"
              maxLength={50}
              required
            />

            <TextField
              label={`Description: (${
                state.ownersAreaResource.asset.description.length
              }/255 characters)`}
              name="description"
              placeholder="Enter a description.."
              value={state.ownersAreaResource.asset.description}
              onChange={e => handleInputChange({
                target: {
                  name: e.target.name,
                  value: e.target.value,
                  objectRef: 'asset',
                },
              })}
              multiline
              rows={4}
              maxLength={255}
              ariaLabel="Description"
              required
            />

            <Stack>
              <Label>Media ID</Label>
              <Stack horizontal tokens={{ childrenGap: 24 }}>
                <TextField
                  name="mediaId"
                  value={state.ownersAreaResource.asset.mediaId}
                  readOnly
                  placeholder="Select an image.."
                  ariaLabel="Media ID"
                />
                <DefaultButton
                  onClick={() => setDisplayMediaPicker(true)}
                >
                  Select Media
                </DefaultButton>
              </Stack>
            </Stack>

            {displayMediaPicker && (
              <MediaPicker
                onDismiss={() => setDisplayMediaPicker(false)}
                onSelectItem={e => handleInputChange({
                  target: { name: 'mediaId', value: e.id, objectRef: 'asset' },
                })}
              />
            )}

            {isCreatingNewOwnersAreaResource ? (
              <Stack>
                <Label>File</Label>
                <input
                  type="file"
                  name="path"
                  ref={inputRef}
                />
              </Stack>
            )
              : (
                <TextField
                  name="path"
                  label="Path"
                  value={state.ownersAreaResource.asset.path}
                  readOnly
                  placeholder="No file associated"
                  ariaLabel="Path"
                  required
                />
              )
            }

            <Stack tokens={{ childrenGap: 8 }}>
              <Label variant="medium" style={{ fontWeight: 600 }}>
                Associated Parks
              </Label>

              <Checkbox
                label="Select All"
                checked={state.allParks.length === state.ownersAreaResource.parks.length}
                onChange={() => toggleSelectAllParks()}
              />

              <Stack tokens={{ childrenGap: 4 }}>
                {state.allParks.map(({ id, name }) => (
                  <Checkbox
                    key={id}
                    label={name}
                    checked={
                      state.ownersAreaResource.parks
                        .findIndex(
                          o => parseInt(o.id, 10) === parseInt(id, 10),
                        ) >= 0
                    }
                    onChange={e => toggleSelectedPark(e, id)}
                  />
                ))}
              </Stack>
            </Stack>

            <PrimaryButton type="submit">Save</PrimaryButton>
          </Stack>
        </form>
      </PivotItem>
    </Pivot>
  );
};

UpdateOwnersAreaResource.propTypes = propTypes;

export default withApollo(UpdateOwnersAreaResource);
