import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_FILES = gql`
  query allOwnersAreaResources($cursorPagination: CursorPaginationCriteria, $criteria: AssetCriteria) {
    allOwnersAreaResources(cursorPagination: $cursorPagination, criteria: $criteria) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          asset {
            id
            media {
              path
            }
            title
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const REMOVE_ASSETS_FROM_OWNERS_AREA_RESOURCES = gql`
  mutation removeAssetsFromOwnersAreaResources($input: [RemoveAssetsFromOwnersAreaResourcesInput]!) {
    removeAssetsFromOwnersAreaResources(input: $input) {
      id
    }
  }
`;
